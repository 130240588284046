/* Custom colors ▼
-------------------------------- */

$white: #fff;
$white_alpha: rgba($white, 0.5);
$black: #000;
$black_01: #212121;

$gray_01: #a3a3a3;

// SNS用カラー
$color_twitter: #1DA1F2;
$color_line: #00B900;

$alt_text: $black_01;

/* Theme setup ▼
-------------------------------- */

$global-font-size: 100%;

// body
$body_background: $white;

// fonts
$font-default: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "HiraKakuPro-W3", "ＭＳ Ｐゴシック", "Helvetica Neue", Arial, sans-serif !default;
$font-alt: "游明朝","Yu Mincho","游明朝体","YuMincho","ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","HiraMinProN-W3","HGS明朝E","HG明朝E","ＭＳ Ｐ明朝" ,"MS PMincho","ＭＳ 明朝",serif;
$font-english: 'Charmonman', cursive;