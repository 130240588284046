/* Reset ▼
-------------------------------- */

.main *,.main *:before, .main *:after {
	@include box-sizing(border-box);
}

html {
	background-color: $body_background;
	overflow-y: auto;
	text-align: justify;
}

// body
body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-feature-settings: "palt";
	font-family: $font-alt;
	letter-spacing: .05em;
	padding:0;
	margin:0;

	&.no_scroll { overflow: hidden; }

	@include IE11 {
		font-feature-settings: normal;
	}

	@include edge {
		font-feature-settings: "palt";
	}
}

// links
a, a:hover, a:active, a:focus, a:visited,
button, button:hover, button:active, button:focus { outline: 0; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

// list
ul, ul ul {
	padding: 0;
	margin: 0;
}

li {
	list-style-type: none;
	list-style-position: outside;
	padding: 0;
}

.icon {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-family: $font-default;
	margin: 0;
	padding: 0;
	@include fts(20,20);
}

figure {
	margin:0;
	padding: 0;
}

img {
	vertical-align: bottom;
	max-width: 100%;
}

*, *:before, *:after {
	box-sizing: border-box;
}

p {
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
}

button {
	cursor: pointer;
	padding: 0;
	margin: 0;
}
