.footer {
	padding-top: 20px;
	position: relative;
	z-index: 1;
	&__copyright {
		padding-top: 30px;
		padding-bottom: 30px;
		text-align: center;
		@include fts(11,11);
	}
}

.shareSection {
	padding-left: 5px;
	padding-right: 5px;
	@include medium {
		display: flex;
		justify-content: center;

		> * {
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}

.shareList {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-left: -5px;
	margin-right: -5px;
	position: relative;
	z-index: 100;

	> li {
		padding-left: 5px;
		padding-right: 5px;
	}
	.fb_iframe_widget span {
		vertical-align: top !important;
	}
	.filmarks {
		height: 20px;
		vertical-align: top;
	}
}