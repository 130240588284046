html {
	background-color: $body_background;
	overflow-y: auto;
	text-align: justify;
}

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-feature-settings: "palt";
	font-family: $font-default;
	letter-spacing: .03em;
	padding:0;
	margin:0;
	//overflow-x: hidden;
	word-wrap: break-word;
	overflow-wrap: break-word;
	@include IE11 {
		font-feature-settings: normal;
		height: 100%;
	}
	@include edge {
		font-feature-settings: "palt";
	}
}

*, *:before, *:after {
	box-sizing: border-box;
}

.page {
	$parent: &;
	&__section {
		padding-top: 56px;
		position: relative;
		@include medium {
			padding-top: 80px;
		}
		#{$parent}--top & {
			@include medium {
				padding-top: 0;
			}
		}
	}
	&--dvd {
		overflow: hidden;
	}
}

.mainContents {
	position: relative;
	border-bottom: 1px solid #e8e8e9;

	.page--top & {
		overflow: hidden;
	}
	&__mv {
		@include maxWidth;

		.page--top & {
			position: relative;
		}
		&--story {
			margin-left: -13px;
			margin-right: -13px;
			padding-left: 0;
			padding-right: 0;
			@include medium {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
	&__head {
		width: 100%;
		height: 130px;
		display: flex;
		justify-content: center;
		align-items: center;
		@include medium {
			height: 160px;
		}
	}
	&__main {
		background-image: url(../img/bg_note@2x.png);
		background-size: 28px auto;
		padding-top: 20px;
		padding-bottom: 250px;
		@include medium {
			padding-top: 30px;
			padding-bottom: 130px;
		}
		.page--top & {
			padding-top: 0;
		}
		.page--comment &,
		.page--commentary & {
			padding-top: 0;
		}
		.page--dvd & {
			padding-top: 0;
		}
	}
	&__wrapper {
		@include maxWidth;
	}
	&__jesus {
		position: absolute;
		bottom:10px;
		right: 0;
		width: 100%;
		height: 230px;

		@include medium {
			position: fixed;
			transition: all .7s;
			opacity: 0;
			bottom:-100px;
			z-index: 1;
			width: 160px;
			&.is_scroll {
				transition: all .7s;
				visibility: visible;
				bottom:10px;
				opacity: 1;
			}
		}
	}
}

.mainTitle {
	$parent: &;
	width: 250px;
	margin-left: auto;
	margin-right: auto;
	@include medium {
		width: 300px;
		margin-left: 0;
		margin-right: 0;
	}
	&__img {
		text-align: center;
		&--imgEn {
			position: relative;
			img {
				height: 30px;
				@include medium {
					height: 45px;
				}
			}
			&::after,
			&::before {
				content: "";
				position: absolute;
				top:0;
				background-image: url(../img/sozai_01.svg);
				width: 40px;
				height: 25px;
				@include medium {
					width: 45px;
					height: 30px;
					top:8px;
				}
				#{$parent}--story & {
					display: none;
				}
			}
			&::before {
				transform: scale(-1, 1);
				#{$parent}--news & {
					left: 15%;
				}
				#{$parent}--cast & {
					left: -9%;
					@include medium {
						left: -17%;
					}
				}
				#{$parent}--intro & {
					left: -6%;
					@include medium {
						left: -12%;
					}
				}
				#{$parent}--commentary & {
					left: -5%;
					@include medium {
						left: -9%;
					}
				}
				#{$parent}--comment & {
					left: 4%;
					@include medium {
						left: 2%;
					}
				}
				#{$parent}--movie & {
					left: 16%;
					@include medium {
						left: 14%;
					}
				}
				#{$parent}--theater & {
					left: 4%;
					@include medium {
						left: 2%;
					}
				}
				#{$parent}--storydvd & {
					left: 16%;
					@include medium {
						left: 14%;
					}
				}

			}
			&::after {
				#{$parent}--news & {
					right: 15%;
				}
				#{$parent}--cast & {
					right: -9%;
					@include medium {
						right: -17%;
					}
				}
				#{$parent}--intro & {
					right: -6%;
					@include medium {
						right: -12%;
					}
				}
				#{$parent}--commentary & {
					right: -5%;
					@include medium {
						right: -9%;
					}
				}
				#{$parent}--comment & {
					right: 4%;
					@include medium {
						right: 2%;
					}
				}
				#{$parent}--movie & {
					right: 16%;
					@include medium {
						right: 14%;
					}
				}
				#{$parent}--theater & {
					right: 4%;
					@include medium {
						right: 2%;
					}
				}
				#{$parent}--storydvd & {
					right: 16%;
					@include medium {
						right: 14%;
					}
				}

			}
		}
		&--imgJa {
			margin-top: -5px;
			img {
				height: 18px;
				@include medium {
					height: 20px;
				}
			}

		}
	}
}

.js_reveal,
.js_revealHead {
	@include medium {
		visibility: hidden;
	}
}
