.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background-color: rgba(255,255,255,.7);
	.is-reveal-open & {
		width: 100%;
		right: 0;
	}
	@include medium {
		transition: all 1s;
		background-color: transparent;
	}
	&.is_scroll {
		transition: all 1s;
		background-color: rgba(255,255,255,.7);
	}
	&__wrapper {
		@include maxWidth();
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 55px;
		@include medium {
			height: 80px;
		}
	}
	&__title {
		h1 {
			.page--top & {
				display: none;
			}
		}
		img {
			width: 165px;
			@include medium {
				width: 210px;
				height: 37px;
			}
		}
		a {
			@include hoverNoFocus {
				opacity: .8;
			}
		}
	}
	&__buttons {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		> * {
			width: 40px;
			.icon {
				width: 30px;
				height: 30px;
			}
			a,button {
				color: $black;
				cursor: pointer;
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				@include hoverNoFocus {
					opacity: .7;
				}
			}
		}
	}
}

.navList {
	width: 230px;
	margin-left: auto;
	margin-right: auto;

	> li {
		&:not(:last-child) {
			margin-bottom: 14px;
			@include medium {
				margin-bottom: 18px;
			}
		}
		a {
			display: block;
			@include hoverNoFocus {
				opacity: .8;
			}
		}
	}
	.arrowButton {
		position: relative;
		cursor: pointer;
		@include hoverNoFocus {
			opacity: .8;
		}
		.icon {
			fill: #c9a965;
			width: 15px;
			height: 15px;
			position: absolute;
			top:8px;
			right: 27%;
			transition: all .5s;
			transform: rotate(0deg);
			@include medium {
				top:10px;
				right: 22%;
			}
		}


		&.is_active {
			.icon {
				transition: all .5s;
				transform: rotate(180deg);
			}
		}
	}
	.new-window {
		position: relative;
		.icon {
			fill: #c9a965;
			width: 15px;
			height: 15px;
			position: absolute;
			top:6px;
			right: 20%;
			@include medium {
				top:8px;
				right: 10%;
			}
		}
	}
	&__img {
		text-align: center;

		&--imgEn {
			img {
				width: 150px;
				height: 30px;
				@include medium {
					width: 230px;
					height: 35px;
				}
			}
		}
		&--imgJa {
			margin-top: -14px;
			@include medium {
				margin-top: -9px;
			}
			img {
				width: 153px;
				height: 16px;
				@include medium {
					width: 230px;
					height: 18px;
				}
			}

		}
	}

}

.subNavList {
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: -10px;

	@include medium {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	> li {
		text-align: center;
		margin-bottom: 10px;
		@include medium {
			padding-left: 10px;
			padding-right: 10px;
		}

		a {
			color: $black_01;
			@include fts(12,12);
			font-weight: bold;
			@include medium {
				@include fts(20,20);
			}
		}
	}
}