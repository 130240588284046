.reveal {
	$parent: &;
	background-color: rgba($white,.9);
	border: none;
	padding: 0;
	width: 100%;
	height: 100%;
	top:0 !important;
	left:0 !important;
	outline: 0;


	&__inner {
		@include maxWidth;
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		align-content: center;
		flex-wrap: wrap;
		padding-top: 10px;
		@include medium {
			padding-top: 20px;
		}
		> * {
			width: 100%;
		}
		#{$parent}--video & {
			max-width: 900px;
			margin-left: auto;
			margin-right: auto;
		}
	}


	&__wrapper {
		width: 100%;
		height: calc(100% - 40px);
		#{$parent}--video & {
			height: auto;
		}

	}
	&__button {
		width: 100%;
		height: 40px;
	}
	&__main {
		width: 100%;
		height: 100%;
		position: absolute;
		top:0;
		left: 0;
		@include medium {
			height: auto;
			position: static;
		}
		.gallery & {
			height: auto;
			position: static;
		}
	}
	&__figure {
		text-align: center;
		position: relative;
		&__text {
			position: absolute;
			bottom: -30px;
			right: 5px;
			height: 27px;
			@include medium {
				bottom: 15px;
				right: 30px;
			}
		}
	}

	.closeMenu {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: auto;
		margin-right: 0;
		@include hoverNoFocus {
			opacity: .7;
		}
		.icon {
			width: 30px;
			height: 30px;
		}
	}
}

.youtube {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.slick-arrow {
	width: 40px;
	height: 80px;
	text-indent: -99999px;
	position: absolute;
	top: calc(50% - 40px);
	z-index: 100;
	cursor: pointer;
	&.slick-prev {
		background-image: url(../img/btn_prev.png);
		left: 0;
	}
	&.slick-next {
		background-image: url(../img/btn_next.png);
		right: 0;
	}
}