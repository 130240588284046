.topMainVisual {
	padding-bottom: 45px;
	overflow: hidden;

	@include medium {
		padding-bottom: 65px;
	}

	&__award {
		max-width: 340px;
		margin-left: auto;
		margin-right: auto;
		height: 55px;
		display: flex;
		align-items: center;
		@include medium {
			max-width: 590px;
			height: 80px;
			margin-left: 5px;
			margin-right: 0;
		}
		@include large {
			margin-left: auto;
			margin-right: auto;
		}
		img {
			width: 100%;
		}
	}
	&__mv {
		@include medium {
			max-width: 670px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__title {
		max-width: 350px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 15px;
		margin-bottom: 55px;
		@include medium {
			margin-top: 30px;
			margin-bottom: 30px;
			max-width: 670px;
		}
	}
	&__text {

		@include medium {
			display: flex;
			justify-content: center;
		}
		span {
			display: block;
			text-align: right;
			line-height: 0;
			&:not(:last-child) {
				margin-bottom: 10px;
				@include medium {
					margin-bottom: 0;
					padding-left: 5px;
					padding-right: 5px;
				}
			}
			img {
				height: 18px;
				@include large {
					height: 25px;
				}
				&.sp-small {
					height: auto;
					width: 198px;
					@include large {
						height: 25px;
						width: auto;
					}
				}
			}
		}
		a {
			@include hoverNoFocus {
				opacity: .8;
			}
		}
	}

	&__dvd {
		text-align: center;
		@include medium {
			padding-top: 20px;
		}
	}
}

.topMovie {
	background-image: url(../img/photo_top_movie.jpg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	width: 100%;
	height: 627px;
	display: flex;
	align-items: center;
	@include medium {
		background-image: url(../img/photo_top_movie_pc.jpg);
		height: 470px;
	}
	@include large {
		background-size: auto 470px;
	}
	&__inner {
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		@include medium {
			max-width: 960px;
		}
	}
}

.movieCard {
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;

	margin-left: auto;
	margin-right: auto;
	@include medium {
		max-width: 315px;
	}
	&__movie {
		background-color: #fff;
		line-height: 0;
		position: relative;
		button {
			@include hoverNoFocus {
				opacity: .8;
			}
		}
		.movieImage {
			border: 2px solid #c9a965;
		}
		img {
			@include medium {
				width: 100%;
			}
		}
	}
	&__play {
		width: 25px;
		position: absolute;
		top:calc(50% - 15px);
		left:calc(50% - 12px);
		img {
			width: 25px;
		}
	}
	&__text {
		height: 20px;
		text-align: center;
		margin-top: 20px;
		@include medium {
			margin-top: 10px;
		}
		img {
			height: 20px;
			vertical-align: top;
			display: inline-block;
		}
	}
}

.movie {
	background-image: url(../img/photo_top_movie.jpg);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	width: 100%;
	height: 627px;

	@include medium {
		background-image: url(../img/photo_movie_pc.jpg);
		height: 600px;
	}
	@include large {
		background-size: auto 600px;
	}
	&__inner {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		@include medium {
			padding-bottom: 80px;
		}
		@include large {
			width: 100%;
			max-width: 1140px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 45px;
			padding-right: 45px;
		}
	}

	&__thumbnail {
		width: 100%;

		@include medium {
			max-width: 960px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

.dvdBannerBlock {
	position: relative;
	@include medium {
		max-width: 970px;
		margin-left: auto;
		margin-right: auto;
	}
}
.topBanner {
	width: 135px;
	position: absolute;
	bottom:-20px;
	@include medium {
		width: 219px;
		bottom:200px;
		left: 0;
	}
	a {
		@include hoverNoFocus {
			opacity: .8;
		}
	}

}

.topCommentSlider {
	background-image: url(../img/bg_note@2x.png);
	background-size: 28px auto;
	margin-left: -13px;
	margin-right: -13px;
	padding-top: 50px;
	padding-bottom: 50px;
	@include medium {
		position: absolute;
		top:250px;
		right:15px;
		width: 230px;
		height: 306px;
		background-image: none;
		padding-top: 0;
		padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
	}
	&__wrapper {
		width: 230px;
		height: 306px;
		margin-left: auto;
		margin-right: auto;
		background-image: url(../img/bg_card.png);
		background-size: 230px 306px;
		background-repeat: no-repeat;
		background-position: center center;
		padding: 45px 30px 30px 25px;
		margin-bottom: 30px;
		transform: rotate(5deg);
		@include medium {
			margin-bottom: 0;
		}


	}
	&__title {
		max-width: 152px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
	}
	&__main {
		margin-bottom: 30px;
		width: 170px;
		height: 120px;
		.commentSlider {
			display: none;
			&.slick-initialized{
				display: block;
			}
		}

	}
	&__button {
		max-width: 45px;
		margin-left: auto;
		margin-right: auto;
		a {
			display: block;
			@include hoverNoFocus {
				opacity: .8;
			}
		}
	}

}

.news {
	@include medium {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	&__list {
		> li {
			&:not(:last-child) {
				margin-bottom: 25px;
			}
			a {
				@include hoverNoFocus {
					text-decoration: underline;
				}
			}
		}
	}
}

.cast {
	@include medium {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	&__list {
		> li {
			&:not(:last-child) {
				margin-bottom: 45px;
				@include medium {
					margin-bottom: 50px;
				}
			}
		}
	}
}

.intro {
	@include medium {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}
	&__image {
		margin-bottom: 20px;
		max-width: 330px;
		margin-left: auto;
		margin-right: auto;
		@include medium {
			max-width: 670px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 40px;
		}
		img {
			width: 100%;
		}
	}
	&__main {
		p {
			@include fts(15,27);
			@include medium {
				@include fts(16,28);
			}
			&:not(:last-child) {
				margin-bottom: 30px;
			}

		}
		&--dvd {
			margin-top: 35px;
			@include medium {
				margin-top: 50px;
			}
		}
	}
}

.story {
	margin-top: 80px;
	padding-top: 30px;
	padding-bottom: 30px;
	position: relative;
	@include medium {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	&::before,
	&::after {
		content:"";
		position: absolute;
		background-image: url(../img/sozai_02.svg);
		width: 120px;
		height: 30px;
		background-repeat: no-repeat;
		background-position: center center;
		left: calc(50% - 60px);
		@include medium {
			width: 140px;
			height: 40px;
			left: calc(50% - 70px);
		}
	}
	&::before {
		top:0;
	}
	&::after {
		bottom:0;
		transform: scale(1, -1);
	}
	&--dvd {
		padding-top: 0;
		padding-bottom: 0;
		@include medium {
			padding-top: 0;
			padding-bottom: 0;
		}
		&::before,
		&::after {
			display: none;
		}
	}

	&__head {
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include medium {
			height: 120px;
		}
	}
	&__image {
		margin-bottom: 10px;
		margin-left: -13px;
		margin-right: -13px;
		@include medium {
			max-width: 960px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 40px;
		}
	}
	&__text {
		margin-bottom: 20px;
		@include medium {
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 40px;
		}
		p {
			@include fts(16,28);
			font-weight: bold;
      @include medium {
        @include fts(18,34);
      }
		}
	}
	&__stext {
		margin-top: 20px;
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
		@include fts(12,19);
		font-weight: normal;
		@include medium {
			@include fts(13,20);
		}
	}
	&__gaga {
		margin-top: 60px;
		p {
			text-align: center;
			@include fts(12,24);
			margin-bottom: 10px;
			@include medium {
				@include fts(13,25);
			}
		}
		text-align: center;
	}
	&__button {
		margin-top: 100px;
		a {
			max-width: 283px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			color: $black_01;
			position: relative;
			height: 80px;
			border-radius: 2px;
			background-image: url(../img/dvd/btn_website@2x.png);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 250px auto;
			@include fts(15,15);
			@include medium {
				@include fts(16,16);
			}
			@include hoverNoFocus {
				opacity: .8;
			}

		}

	}

}

.storySlider {
	&__child {
		padding-left: 7px;
		padding-right: 7px;
	}
	&__image {
		@include medium {
			width: 310px;
		}
	}

}

.dvdInfo {
	width: 100%;
	min-height: 550px;
	background-image: url(../img/bg_comment.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 100px 15px;
	@include medium {
		background-image: url(../img/bg_comment_pc.jpg);
		min-height: 750px;
		padding: 100px 20px;

	}
	&__main {
		max-width: 510px;
		margin-left: auto;
		margin-right: auto;
		padding: 25px 20px;
		background-color: rgba(255,255,255,.85);
		@include medium {
			padding: 25px 40px;
		}
	}
	&__inner {
		padding-top: 30px;
		padding-bottom: 30px;
		position: relative;
		@include medium {
			padding-top: 40px;
			padding-bottom: 40px;
		}
		&::before,
		&::after {
			content:"";
			position: absolute;
			background-image: url(../img/sozai_02.svg);
			width: 120px;
			height: 30px;
			background-repeat: no-repeat;
			background-position: center center;
			left: calc(50% - 60px);
			@include medium {
				width: 140px;
				height: 40px;
				left: calc(50% - 70px);
			}
		}
		&::before {
			top:0;
		}
		&::after {
			bottom:0;
			transform: scale(1, -1);
		}
	}
	&__head {
		text-align: center;
		padding: 25px 0;
	}
	&__list {
		font-weight: bold;
		@include fts(15,25);
		margin-bottom: 8px;
		@include medium {
			@include fts(18,28);
			margin-bottom: 16px;
		}
		i {
			width: 6px;
			height: 6px;
			background-color: #c9a965;
			border-radius: 3px;
			display: inline-block;
			vertical-align: 3px;
			margin-left: 4px;
			margin-right: 8px;

		}
		small {
			@include fts(12,25);
			font-weight: normal;
		}
	}
	&__stext {
		@include fts(12,28);
		margin-bottom: 20px;
		@include medium {
			@include fts(13,20);
		}

	}
}

.dvd {
	padding: 45px 0;
	@include medium {
		padding: 80px 40px;
		max-width: 950px;
		margin-left: auto;
		margin-right: auto;
	}
	&__list {
		@include medium {
			display: flex;
			margin-left: -40px;
			margin-right: -40px;
		}
		> li {
			&:not(:last-child) {
				margin-bottom: 75px;
				@include medium {
					margin-bottom: 0;
				}
			}
			@include medium {
				padding-left: 40px;
				padding-right: 40px;
				flex: 1;
			}
		}
	}
	&__image {
		position: relative;
		text-align: center;
		max-width: 175px;
		margin-left: auto;
		margin-right: auto;
		@include medium {
			max-width: 350px;
			height: 411px;
			display: flex;
			align-items: flex-end;
		}
	}
	&__pic01 {
		position: relative;
		z-index: 10;
		text-align: center;
	}
	&__pic02 {
		position: absolute;
		z-index: 5;
		bottom: 10px;
		right: -40px;
		width: 115px;
		@include medium {
			bottom: 20px;
			right: -85px;
			width: 230px;
		}
	}
	&__head {
		text-align: center;
		background-image: url(../img/dvd/line@2x.png);
		background-repeat: no-repeat;
		background-position: center bottom;
		-webkit-background-size: 100% 2px;
		background-size: 100% 2px;
		padding: 15px 0 ;
		margin-bottom: 15px;
		@include medium {
			padding: 30px 0 20px 0;
			margin-bottom: 20px;
		}
		p {
			@include fts(15,25);
			font-weight: bold;
			@include medium {
				@include fts(16,26);
			}
			small {
				@include fts(14,25);
				font-weight: normal;
				@include medium {
					@include fts(12,26);
				}
			}
		}
	}
	&__title {
		margin-bottom: 10px;
		img {
			height: 21px;
		}
	}
	&__content {
		padding: 0 15px;
		@include medium {
			padding: 0 25px;
		}
	}
	&__text {
		@include fts(12,18);
		margin-bottom: 25px;
		@include medium {
			@include fts(13,20);
			margin-bottom: 30px;
		}
	}
	&__link {
		text-align: center;
		width: 160px;
		margin-left: auto;
		margin-right: auto;
		a {
			@include fts(15,25);
			font-weight: bold;
			text-align: center;
			color: $white;
			background-color: #c9a965;
			width: 160px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			@include hoverNoFocus {
				opacity: .8;
			}
		}
	}
	&__bottom {
		text-align: center;
		margin-top: 65px;
		p {
			margin-top: 10px;
			text-align: center;
			@include fts(12,22);
			@include medium {
				@include fts(13,23);
			}
		}
	}
}

.comment {

	&__sticky {
		width: 100%;
		height: 100vh;
		position: sticky;
		top: 0;
		background-image: url(../img/bg_comment.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		@include medium {
			background-image: url(../img/bg_comment_pc.jpg);
		}
	}
	&__main {
		@include maxWidth;
		min-height: 100vh;
		position: relative;
		margin-top:-100vh;
		padding-top: 80px;
		padding-bottom: 80px;
	}
	&__list {
		margin-bottom: -40px;
		@include medium {
			max-width: 920px;
			margin-left: auto;
			margin-right: auto;
			display: flex;
			flex-wrap: wrap;
		}
		> li {
			margin-bottom: 40px;
			@include medium {
				width: 50%;
				padding-left: 25px;
				padding-right: 25px;
			}
		}
	}

}

.tabs {
	display: flex;
	border: none;
	position: relative;
	margin-bottom: 30px;
	height: 63px;
	max-width: 688px;
	margin-left: auto;
	margin-right: auto;
	&::after {
		content: "";
		position: absolute;
		width: 8px;
		height: 100%;
		background-image: url(../img/line_tab_center.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 8px auto;
		left: calc(50% - 4px);
		top:0;
	}
	&.active {
		&::after {
			transform: scale(-1, 1);
		}
	}

	> li {
		padding-left: 4px;
		padding-right: 4px;

	}
}

.tabs-title {
	flex: 1;
	position: relative;
	> a {
		@include fts(15,15);
		text-align: center;
		color: #a8a8a8;
		@include hover {
			color: $black_01;
		}
	}
	&::after {
		content:"";
		position: absolute;
		width: 100%;
		height: 4px;
		background-image: url(../img/line_tab_left.png);
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: auto 4px;
		bottom:-2px;
		right: 4px;
		@include medium {
			bottom:0;
		}

	}
	&:nth-child(2) {
		&::after {
			transform: scale(-1, 1);
			left: 4px;

		}
	}
	&.is-active {
		&::after {
			top:2px;
			@include medium {
				top:0;
			}
		}
	}
	> a {
		padding: 24px 0;
	}
}

.tabs-title > a:focus,
.tabs-title > a[aria-selected='true'] {
	background-color: transparent;
	color: $black_01;
	position: relative;
	z-index: 10;

}

.tabs-content {
	background-color: transparent;
}

.tabs-content.vertical {
	border: none;
	padding: 0;

}

.tabs-panel {
	padding: 0;
	padding-top: 50px;
	@include medium {
		padding-top: 80px;
		max-width: 700px;
		margin-right: auto;
		margin-left: auto;
	}
}

.commentary {
	&__head {
		text-align: center;
		margin-bottom: 25px;
	}
	&__title {
		@include fts(18,27);
		font-weight: bold;
		margin-bottom: 5px;
		@include medium {
			@include fts(22,31);
			margin-bottom: 10px;
		}
	}
	&__headText {
		font-weight: bold;
		@include fts(13,24);
		@include medium {
			@include fts(16,28);
		}
	}
	&__main {
		p {
			@include fts(14,26);
			@include medium {
				@include fts(16,28);
			}
		}
		> * {
			&:not(:last-child) {
				margin-bottom: 25px;
			}
		}
	}
	&__button {
		margin-top: 100px;
		a {
			max-width: 250px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			color: $black_01;
			position: relative;
			height: 60px;
			border-radius: 2px;
			background-image: url(../img/btn_commentary.png);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 250px auto;
			@include fts(15,15);
			@include medium {
				@include fts(16,16);
			}
			@include hoverNoFocus {
				opacity: .8;
			}

		}
		&.is_reverse {
			a {
				background-image: url(../img/btn_commentary_2.png);
			}

		}

	}

}

.jesus {
	@include maxWidth;
	a {
		display: block;
		width: 145px;
		height: 230px;
		margin-left: auto;
		margin-right: auto;
		@include medium {
			margin-right: 0;
		}

	}
	&__image {
		width: 145px;
		height: 200px;
		background-image: url(../img/img_jesus_01.png);
		background-size: 280px auto;
		background-position: 0 0;
		animation:fuwafuwa 1.2s infinite ease-in-out alternate;
		background-repeat: no-repeat;
		position: relative;
		cursor: pointer;
		z-index: 100;

		@keyframes fuwafuwa {
			0% {
				top:0;
			}
			100% {
				top:20px;
			}
		}
		@include hoverNoFocus {
			background-position: -140px 0;

		}
	}
	&__bottom {
		margin-left: auto;
		margin-right: auto;
		width: 145px;
		height: 30px;
		background-image: url(../img/img_jesus_02.png);
		background-size: 145px auto;
		background-position: 0 0;
		animation:fuwafuwa2 1.2s infinite ease-in-out alternate;
		position: relative;
		cursor: pointer;
		@include large {
			margin-right: 0;
		}
		@keyframes fuwafuwa2 {
			0% {
				transform: scale(.7);
			}
			100% {
				transform: scale(1);
			}
		}

	}
}

.topCast {
	padding-top: 80px;
	padding-bottom: 0;
	position: relative;
	@include medium {
		padding-top: 110px;
	}
	&__main {
		padding-bottom: 40px;
		@include medium {
			display: flex;
			justify-content: center;
		}
		&__top {
			display: flex;
			justify-content: center;
			margin-bottom: 25px;
      padding-left: 20px;
      padding-right: 20px;
			@include medium {
				order: 2;
				margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
			}
			p {
				padding-left: 12px;
				padding-right: 12px;
        @include medium {
          padding-left: 22px;
          padding-right: 22px;
        }
				&:nth-child(1) {
					order: 2;
					img {
						width: calc(384px / 2);
						@include medium {
							width: 300px;
						}
					}
				}
				&:nth-child(2) {
					order: 1;
					img {
						width: calc(145px / 2);
						@include medium {
							width: 95px;
						}
					}
				}
			}
		}
		&__bottom {
			display: flex;
			justify-content: center;
			@include medium {
				order: 1;
			}
			p {
				padding-left: 12px;
				padding-right: 12px;
        @include medium {
          padding-left: 22px;
          padding-right: 22px;
        }
				&:nth-child(1) {
					img {
						width: calc(346px / 2);
						@include medium {
							width: 190px;
						}
					}
				}
			}
		}

	}
	&__image {
		max-width: calc(644px / 2);
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
		@include medium {
			max-width: calc(1226px / 2);
		}
	}
	&__text {
		@include fts(11,17);
		text-align: center;
		@include medium {
			@include fts(14,24);
		}
		small {
			@include fts(11,17);
			@include medium {
				@include fts(12,24);
			}
		}
	}
}

.theater {
	max-width: 760px;
	margin-left: auto;
	margin-right: auto;
	&__areaSection {
		&:not(:last-child) {
			margin-bottom: 20px;
			@include medium {
				margin-bottom: 40px;
			}
		}
	}
	&__title {
		text-align: center;
		font-weight: bold;
		@include fts(16,24);
		margin-bottom: 15px;
		@include medium {
			@include fts(18,26);
			margin-bottom: 20px;
		}
	}
}

.memo {
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	@include fts(12,20);
	margin-top: 50px;
	@include medium {
		margin-top: 70px;
	}
}