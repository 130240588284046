.newsCard {
	@include medium {
		display: flex;
	}
	&__time {
		@include fts(13,22);
		font-weight: bold;
		display: block;
		margin-bottom: 5px;
		@include medium {
			width: 140px;
			@include fts(16,25);
			margin-bottom: 0;
		}
	}
	&__main {
		@include fts(15,24);
		font-weight: bold;
		@include medium {
			flex: 1;
			@include fts(16,25);
		}
		a {
			color: $black_01;
		}
	}
}


.castCard {
	@include medium {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
	}
	@include large {
		max-width: none;
	}
	&__top {
		display: flex;
		align-items: flex-end;
		padding-bottom: 20px;
		margin-bottom: 15px;
		position: relative;
		@include medium {
			margin-bottom: 0;
			display: block;
			padding-bottom: 0;
			.cast__list > li:nth-child(even) & {
				order: 2;
			}
		}
		&::after {
			content: "";
			position: absolute;
			bottom:0;
			background-image: url(../img/line.png);
			background-size: 100% auto;
			background-repeat: no-repeat;
			width: 100%;
			height: 3px;
			@include medium {
				display: none;
			}
		}
	}
	&__image {
		width: 125px;
		@include medium {
			width: 250px;
		}
	}
	&__title {
		flex: 1;
		padding-left: 15px;
		@include medium {
			padding-left: 0;
			display: flex;
			align-items: flex-end;
			padding-bottom: 18px;
			position: relative;
			margin-bottom: 26px;
			&::after {
				content: "";
				position: absolute;
				bottom:0;
				left:0;
				background-image: url(../img/line_pc.png);
				background-size: 100% auto;
				background-repeat: no-repeat;
				width: 100%;
				height: 3px;
			}
		}
	}
	&__main {
		@include medium {
			flex: 1;
			padding-left: 26px;
			.cast__list > li:nth-child(even) & {
				order: 1;
				padding-right: 46px;
				padding-left: 0;
			}
		}
		@include large {
			padding-left: 46px;
		}

	}
	&__subText {
		@include fts(13,23);
		font-weight: bold;
		@include medium {
			padding-left: 20px;
			@include fts(14,24);
		}
	}
	&__name {
		margin-bottom: 5px;
		@include medium {
			flex-shrink: 1;
			margin-bottom: 0;
		}
		img {
			height: 22px;
			@include medium {
				height: 30px;

			}
		}
	}
	&__text {
		@include fts(14,25);
		@include medium {
			@include fts(15,26);
		}
	}
}

.commentCard {
	padding: 25px 15px;
	background-color: rgba(255,255,255,.7);
	width: 100%;
	height: 100%;
	@include medium {
		padding: 25px;
	}
	&__main {
		@include fts(14,24);
		font-weight: bold;
		padding-bottom: 30px;
		position: relative;
		margin-bottom: 15px;
		&::after {
			content: "";
			position: absolute;
			bottom:0;
			background-image: url(../img/line.png);
			background-size: 100% auto;
			background-repeat: no-repeat;
			width: 100%;
			height: 3px;
		}
		p {
			@include fts(14,24);
			@include medium {
				@include fts(15,26);
			}
		}
	}
	&__name {
		@include fts(17,17);
		font-weight: bold;
		text-align: right;
		margin-bottom: 8px;
		@include medium {
			@include fts(18,18);
		}
	}
	&__subText {
		@include fts(12,12);
		text-align: right;
	}

}

.topCommentSliderCard {
	width: 100%;
	&__main {
		@include fts(11,20);
		font-weight: bold;
		@include abridgement(1.81818,11,4,#fff);
	}
	&__name {
		@include fts(15,15);
		font-weight: bold;
		text-align: right;
		margin-top: 10px;
		margin-bottom: 5px;
	}
	&__subText {
		@include fts(11,18);
		text-align: right;
	}

}

.theaterTable {
	padding-bottom: 2px;
	position: relative;
	width: 100%;
	@include medium {
		display: flex;
		padding: 10px 0;
	}
	&::after {
		@include medium {
			content: "";
			position: absolute;
			bottom:0;
			left: 0;
			width: 100%;
			height: 2px;
			background-image: url(../img/line_theater_pc@2x.png);
			background-size: auto 2px;
			background-repeat: no-repeat;
		}
	}
	.theaterHead & {
		background-color: $black_01;
		padding: 10px 12px;
		margin-left: -12px;
		margin-right: -12px;
		width: calc(100% + 24px);
		@include medium {
			margin-left: 0;
			margin-right: 0;
			padding: 10px 0;
			width: 100%;
		}
		&::after {
			display: none;
		}
	}
	&__area {
		@include fts(14,22);
		font-weight: bold;
		position: relative;
		padding: 10px 0;
		@include medium {
			width: 120px;
			padding: 0 20px;
			@include fts(15,23);
		}
		.theaterHead & {
			@include fts(13,21);
			color: $white;
			display: none;
			font-weight: normal;
			@include medium {
				display: block;
			}
		}
		&::after {
			content: "";
			position: absolute;
			bottom:0;
			left: 0;
			width: 100%;
			height: 2px;
			background-image: url(../img/line_theater@2x.png);
			background-size: 100% 2px;
			background-repeat: no-repeat;
			@include medium {
				display: none;
			}
		}

	}
	&__main {
		padding: 5px 0;
		@include medium {
			flex: 1;
			padding: 0;
		}
		.theaterHead & {
			padding: 0;
		}
	}
	&__inner {
		display: flex;
		padding: 5px 0;
		.theaterHead & {
			padding: 0;
		}
	}
	&__name {
		@include fts(14,22);
		flex: 1;
		.theaterHead & {
			@include fts(13,21);
			color: $white;
		}
		@include medium {
			padding-left: 20px;
			padding-right: 20px;
			@include fts(15,23);
		}
		a {
			color: #c43442;
			font-weight: bold;
			text-decoration: underline;
			@include hoverNoFocus {
				text-decoration: none;
			}
		}
	}
	&__date {
		@include fts(14,22);
		width: 120px;
		text-align: right;
		.theaterHead & {
			@include fts(13,21);
			color: $white;
		}
		@include medium {
			@include fts(15,23);
			width: 160px;
			padding-left: 20px;
			padding-right: 20px;
			text-align: left;
		}
	}
}